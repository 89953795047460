
export const portfolioData = [
    {
        "cover": require("../../portfolio/11/adjustermatch.PNG"),
        "title": "Adjuster Match",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "Angular, C#",
        "url": "https://dev-adjuster-match.inspectormatch.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/12/chooseceltic.PNG"),
        "title": "Celtic Construction Solutions",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "WordPress",
        "url": "https://chooseceltic.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/13/dronesnap.PNG"),
        "title": "Drone Snap v2",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "Angular, C#",
        "url": "https://dev-drone-snap.inspectormatch.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/14/dronesnap2.PNG"),
        "title": "Drone Snap v1",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "WordPress",
        "url": "https://dronesnap.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/15/idenergy.PNG"),
        "title": "ID Energy v2",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "Angular, C#",
        "url": "https://idenergy.netlify.app",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/16/idenergy2.PNG"),
        "title": "ID Energy v1",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "WordPress",
        "url": "https://idenergy.org",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/17/inspectiondepot.PNG"),
        "title": "Inspection Depot",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "WordPress",
        "url": "https://inspectiondepot.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/18/inspectormatch.PNG"),
        "title": "Inspector Match",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "WordPress",
        "url": "https://inspectormatch.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/19/ondemandtarping.PNG"),
        "title": "On Demand Tarping",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "WordPress",
        "url": "https://ondemandtarping.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/20/paperlessinspectors.PNG"),
        "title": "Paperless Inspectors",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "Angular, C#",
        "url": "https://paperlessinspectors.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/21/termitedepot.PNG"),
        "title": "Termite Depot v2",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "Angular, C#",
        "url": "https://dev-termitedepot.inspectormatch.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/22/termitedepot2.PNG"),
        "title": "Termite Depot v1",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "WordPress",
        "url": "https://termitedepot.com",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/2/svm.JPG"),
        "title": "Scotts Valley Magnetics Website",
        "description": "Company website developed for electronics manufacturing company Scotts Valley Magnetics.",
        "stack": "React",
        "url": "https://scottsvalleymagnetics.netlify.app/",
        "src": "https://github.com/HorvathTeddy/svmagnetics"
    },
    {
        "cover": require("../../portfolio/1/sync.JPG"),
        "title": "Sync",
        "description": "Music collaboration app utilizing advanced AI and machine learning to match artists, lyricists, and producers.",
        "stack": "React, Firebase",
        "url": "https://musicsync.netlify.app",
        "src": "https://github.com/HorvathTeddy/sync"
    },
    {
        "cover": require("../../portfolio/10/dolla.JPG"),
        "title": "Dolla",
        "description": "Virtual Banking Made Easy",
        "stack": "React, Firebase",
        "url": "https://dolla-web.netlify.app/",
        "src": "https://github.com/leovenom/react-website-smooth-scroll"
    },
    {
        "cover": require("../../portfolio/7/chefit.JPG"),
        "title": "Chef'it",
        "description": "Food ordering app for people to cook their own food and sell it on the app.",
        "stack": "MongoDB, Express, React, Node.js",
        "url": "https://chefit.io/",
        "src": "https://github.com/thedigitalchief/chef-api"
    },
    {
        "cover": require("../../portfolio/8/startupucr.JPG"),
        "title": "StartupUCR",
        "description": "App for startups and founders to engage, brainstorm, and collaborate.",
        "stack": "MongoDB, Express, React, Node.js",
        "url": "https://startupucr.com/",
        "src": "https://github.com/thedigitalchief/startupucr-fullstack-website"
    },
    {
        "cover": require("../../portfolio/3/uhr.PNG"),
        "title": "Uhr",
        "description": "On demand delivery service with a user and driver application.",
        "stack": "React Native, AWS Amplify",
        "url": "https://github.com/HorvathTeddy/SprintDeliveries",
        "src": "https://github.com/HorvathTeddy/SprintDeliveries"
    },
    // {
    //     "cover": require("../../portfolio/4/tinder.JPG"),
    //     "title": "Tinder Clone",
    //     "description": "Full stack tinder clone.",
    //     "stack": "MongoDB, Express, React, Node.js",
    //     "url": "https://tinder-clone-e5610.firebaseapp.com/",
    //     "src": "https://github.com/HorvathTeddy/tinder-clone"
    // },
    {
        "cover": require("../../portfolio/5/taskmaster.JPG"),
        "title": "Task Master",
        "description": "A full stack task manager application for business owners",
        "stack": "React Native, MongoDB, Express, Node.js",
        "url": "https://github.com/HorvathTeddy/taskmaster",
        "src": "https://github.com/HorvathTeddy/taskmaster"
    }
    // {
    //     "cover": require("../../portfolio/6/pongjs.JPG"),
    //     "title": "Pong",
    //     "description": "Pong in web browser with AI opponent",
    //     "stack": "JavaScript",
    //     "url": "https://pong-in-js.netlify.app/",
    //     "src": "https://github.com/HorvathTeddy/pong-js"
    // }
]
