import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faJava, faGitAlt, faJsSquare, faNode, faPython, faReact } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
          setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 3000)
      }, [])

  return (
    <>
        <div className='container about-page' id='about-page-container'>
            <div className='text-zone' id='about-text-zone'>
                <h1>
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['A','b','o','u','t',' ','M','e']}
                        idx={15}   
                    />
                    <p style={{fontSize: '20px'}}>
                        My name is Teddy Horvath and I am dedicated to writing effective and impactful code.
                    </p>
                    <p style={{fontSize: '20px'}}>
                        I am currently seeking new opportunities to leverage my skills. My goal is to improve as many people's lives as I can with software.
                    </p>
                    <p style={{fontSize: '20px'}}>
                        Besides my adoration for all things technology, I also love swimming in the cold ocean, hiking in thick forests, and connecting with friends through a good conversation.
                    </p>
                </h1>
            </div>
            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faPython} color='#4B8BBE' />
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faJava} color='#5382a1' />
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faNode} color='#6cc24a' />
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faReact} color='#5ED4F4' />
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faJsSquare} color='#EFD81D' />
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faGitAlt} color='#F1502F' />
                    </div>
                </div>
            </div>
        </div>
        <Loader type='pacman' />
    </>
  )
}

export default About

