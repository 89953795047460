import './index.scss'
import { Link, NavLink } from 'react-router-dom'
import LogoS from '../../assets/images/headshot.jpg'
//import LogoSubtitle from '../../assets/images/logo_sub.png'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHome, faUser, faSuitcase, faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => {

  const [showNav, setShowNav] = useState(false)  

  return (
    <div className='nav-bar'>
      <Link 
        className='logo' 
        to="/"
        onClick={() => setShowNav(false)}>
        <img src={LogoS} alt='logo' />
      </Link>
      <nav className={showNav ? 'mobile-show' : 'pc-show'}>
        <div className='link-container'>
            <NavLink 
                onClick={() => setShowNav(false)}
                exact='true'
                className='nav-button-icon'
                activeclassname='active' 
                to="/"
            >
                {showNav ? <span className='mobile-menu-text'>HOME</span>: <FontAwesomeIcon icon={faHome} color='#4d4d4e' />}
            </NavLink>
            <NavLink
                onClick={() => setShowNav(false)} 
                exact='true'
                activeclassname='active' 
                className='nav-button-icon'
                to='about'
            >
                {showNav ? <span className='mobile-menu-text'>ABOUT</span>: <FontAwesomeIcon icon={faUser} color='#4d4d4e' />}
            </NavLink>
            <NavLink
                onClick={() => setShowNav(false)}
                exact='true' 
                activeclassname='active' 
                className='nav-button-icon'
                to='/portfolio'
            >
                {showNav ? <span className='mobile-menu-text'>PORTFOLIO</span>: <FontAwesomeIcon icon={faSuitcase} color='#4d4d4e' />}
            </NavLink>
            <NavLink
                onClick={() => setShowNav(false)}
                exact='true'
                activeclassname='active' 
                className='nav-button-icon'
                to='/contact'
            >
                {showNav ? <span className='mobile-menu-text'>CONTACT</span>: <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e' />}
            </NavLink>
            <div className='btn-links'>
                <a
                    onClick={() => setShowNav(false)}
                    exact='true'
                    activeclassname='active' 
                    className='nav-button-icon'
                    rel='noreferrer'
                    href='https://github.com/HorvathTeddy'
                    target='_blank'
                >
                    <FontAwesomeIcon icon={faGithub} color='#4d4d4e' className='btn-link' />
                </a>
                <a
                    onClick={() => setShowNav(false)}
                    exact='true'
                    activeclassname='active' 
                    className='nav-button-icon'
                    rel='noreferrer'
                    href='https://www.linkedin.com/in/teddyhorvath/'
                    target='_blank'
                >
                    <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e' className='btn-link' />
                </a>
            </div>
        </div>
        <FontAwesomeIcon 
            onClick={() => setShowNav(false)}
            icon={faClose}
            color='#ffd700'
            size='3x'
            className='close-icon'
        />
      </nav>
      <ul>
        <li>
            <a
                target='_blank'
                rel='noreferrer'
                href='https://www.linkedin.com/in/teddyhorvath/'    
            >
                <FontAwesomeIcon icon={faLinkedin} color='#4d4d4e' />
            </a>
        </li>
        <li>
            <a
                target='_blank'
                rel='noreferrer'
                href='https://github.com/HorvathTeddy'    
            >
                <FontAwesomeIcon icon={faGithub} color='#4d4d4e' />
            </a>
        </li>
      </ul>
      <FontAwesomeIcon 
        onClick={() => setShowNav(true)}
        icon={faBars}
        color='#ffd700'
        size='3x'
        className='burger-icon'
      />
    </div>
  )
}

export default Sidebar
